import React from "react";
import HeroStyle3 from "../Hero/HeroStyle3";
import AboutPage from "./One/AboutMe";
import Skills from "./One/Skills";
import HowWork from "./One/HowWork";

import Spacing from "../Spacing";
import SectionHeading from "../SectionHeading";
import Portfolio from "../Portfolio";
import Button from "../Button";
import { pageTitle } from "../../helpers/PageTitle";
import { useTranslation } from "react-i18next";
import WaterWave from "react-water-wave";

const portfolioData = [
  "psc-119-app",
  "psc-119-bangka",
  "siadum-dinkes",
  "kpb-lada-babel",
  "fkm-unsri",
  "mpp-bangka",
  // "pt-indometal-asia",
];

export default function Home({ darkMode }) {
  const [t] = useTranslation("global");
  pageTitle("Home");
  return (
    <>
      <div
        className="cs_bg_filed"
        style={{
          backgroundImage: `url('/images/studio-agency/hero_bg_dark.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* {({ drop }) => ( */}
        <HeroStyle3
          title="Full Stack Developer"
          // scrollingText="We Create Web Content - Web App - Mobile App - Databse Engginer - "
          scrollingText="FronT End - Back End Developer - Data Analysis"
          thumbnailSrc={
            darkMode
              ? "/images/studio-agency/hero_bg_dark.jpg"
              : "/images/studio-agency/hero_bg.jpg"
          }
        />
        {/* )} */}
      </div>
      <section className="" id="about">
        <AboutPage></AboutPage>
        <HowWork></HowWork>
      </section>
      <section className="cs_primary_bg" id="skills">
        <Skills></Skills>
      </section>
      <section id="portofolio">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title={t("section.portfolio.title")}
            subTitle={t("section.portfolio.subtitle")}
          />
          <Spacing lg="85" md="45" />
          <Portfolio compact_data={portfolioData} />
          <Spacing lg="26" md="30" />
          <div className="text-center mb-5">
            <Button btnText={t("home.see_all_project")} btnUrl="/portfolio" />
          </div>
        </div>
      </section>
    </>
  );
}
