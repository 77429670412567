import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import "../../../src/custom.css";

export default function Header({
  logoUrl,
  colorVariant,
  cart,
  actionBtnText,
  actionBtnUrl,
}) {
  const [isSticky, setIsSticky] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const { t, i18n } = useTranslation("global");
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("selectedLanguage", lng);
  };
  const scrollToSection = (elementRef) => {
    // elementRef.preventDefault();
    window.scrollTo({
      top: document.querySelector(elementRef).offsetTop,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    if (selectedLanguage) {
      // Set the language to the stored language
      i18n.changeLanguage(selectedLanguage);
      setCurrentLanguage(selectedLanguage);
    } else {
      // If no language stored, set a default language
      changeLanguage("en"); // You can set your desired default language here
    }

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);
  return (
    <header
      className={`cs_site_header cs_style_1 cs_sticky_header ${
        colorVariant ? colorVariant : "cs_primary_color"
      } ${isSticky ? "cs_gescout_show" : ""}`}
    >
      <div className="cs_main_header">
        <div className="container">
          <div className="cs_main_header_in">
            <div className="cs_main_header_left">
              <Link className="cs_site_branding" to="/">
                <img src={logoUrl} alt="Logo" />
              </Link>
            </div>
            <div className="cs_main_header_center">
              <div className="cs_nav cs_medium cs_primary_font">
                <ul
                  className={`${
                    mobileToggle ? "cs_nav_list cs_active" : "cs_nav_list"
                  }`}
                >
                  {/* <li className="menu-item-has-children cs_mega_menu">
                    <Link to="/">Home</Link>
                    <DropDown>
                      <ul className="cs_mega_wrapper">
                        <li className="menu-item-has-children">
                          <Link to="/">Dark Version</Link>
                          <DropDown>
                            <ul>
                              <li>
                                <Link to="/">Creative Agency</Link>
                              </li>
                              <li>
                                <Link to="/marketing-agency">
                                  Marketing Agency
                                </Link>
                              </li>
                              <li>
                                <Link to="/studio-agency">Studio Agency</Link>
                              </li>
                              <li>
                                <Link to="/digital-agency">Digital Agency</Link>
                              </li>
                              <li>
                                <Link to="/tech-startup">Tech Startup</Link>
                              </li>
                            </ul>
                          </DropDown>
                        </li>
                        <li className="menu-item-has-children">
                          <Link to="/">Light Version</Link>
                          <DropDown>
                            <ul>
                              <li>
                                <Link to="/light">Creative Agency</Link>
                              </li>
                              <li>
                                <Link to="/light/marketing-agency">
                                  Marketing Agency
                                </Link>
                              </li>
                              <li>
                                <Link to="/light/studio-agency">
                                  Studio Agency
                                </Link>
                              </li>
                              <li>
                                <Link to="/light/digital-agency">
                                  Digital Agency
                                </Link>
                              </li>
                              <li>
                                <Link to="/light/tech-startup">
                                  Tech Startup
                                </Link>
                              </li>
                            </ul>
                          </DropDown>
                        </li>
                      </ul>
                    </DropDown>
                  </li> */}
                  <li>
                    <HashLink to="/#top">Home</HashLink>
                  </li>
                  <li>
                    <HashLink to="/#skills">Skills</HashLink>
                  </li>
                  <li>
                    <HashLink to="/#about">{t("section.about.title")}</HashLink>
                  </li>
                  <li>
                    <HashLink to="/#portofolio">
                      {t("section.portfolio.title")}
                    </HashLink>
                  </li>
                  {/* <li>
                    <Link to="about">About</Link>
                  </li>

                  <li className="menu-item-has-children">
                    <Link to="service">Service</Link>
                    <DropDown>
                      <ul>
                        <li>
                          <Link to="service">Service</Link>
                        </li>
                        <li>
                          <Link to="service/service-details">
                            Service Details
                          </Link>
                        </li>
                      </ul>
                    </DropDown>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to="blog">Blog</Link>
                    <DropDown>
                      <ul>
                        <li>
                          <Link to="blog">Blog Standard</Link>
                        </li>
                        <li>
                          <Link to="blog-list">Blog List</Link>
                        </li>
                        <li>
                          <Link to="blog/blog-details">Blog Details</Link>
                        </li>
                      </ul>
                    </DropDown>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to="shop">Shop</Link>
                    <DropDown>
                      <ul>
                        <li>
                          <Link to="shop">Our Shop</Link>
                        </li>
                        <li>
                          <Link to="shop/product-details">Shop Details</Link>
                        </li>
                        <li>
                          <Link to="shop/cart">Cart</Link>
                        </li>
                        <li>
                          <Link to="shop/checkout">Checkout</Link>
                        </li>
                        <li>
                          <Link to="shop/success">Success Order</Link>
                        </li>
                        <li>
                          <Link to="shop/wishlist">Wishlist</Link>
                        </li>
                      </ul>
                    </DropDown>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to="/">Pages</Link>
                    <DropDown>
                      <ul>
                        <li>
                          <Link to="portfolio">Portfolio</Link>
                        </li>
                        <li>
                          <Link to="portfolio/portfolio-details">
                            Portfolio Details
                          </Link>
                        </li>
                        <li>
                          <Link to="case-study-details">
                            Case Study Details
                          </Link>
                        </li>
                        <li>
                          <Link to="team">Team</Link>
                        </li>
                        <li>
                          <Link to="team/team-details">Team Details</Link>
                        </li>
                      </ul>
                    </DropDown>
                  </li> */}
                  <li>
                    <Link to="contact">{t("section.contact.title")}</Link>
                  </li>
                  {/* <li>
                    <button
                      onClick={() => {
                        changeLanguage("id");
                      }}
                      className="lang-btn cs_btn cs_style_1"
                    >
                      ID
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => {
                        changeLanguage("en");
                      }}
                      className={`lang-btn cs_btn cs_style_1  ${
                        colorVariant ? "cs_btn_white" : ""
                      }`}
                    >
                      EN
                    </button>
                  </li> */}
                </ul>
                <span
                  className={
                    mobileToggle
                      ? "cs_menu_toggle cs_teggle_active"
                      : "cs_menu_toggle"
                  }
                  onClick={() => setMobileToggle(!mobileToggle)}
                >
                  <span></span>
                </span>
              </div>
            </div>
            <div className="cs_main_header_right">
              {currentLanguage === "id" && (
                <button
                  onClick={() => {
                    changeLanguage("en");
                  }}
                  className={`cs_btn cs_style_1  cs_btn_white`}
                >
                  EN
                </button>
              )}
              {currentLanguage !== "id" && (
                <button
                  onClick={() => {
                    changeLanguage("id");
                  }}
                  className={`cs_btn cs_style_1  ${
                    currentLanguage !== "id" ? "cs_btn_white" : ""
                  }`}
                >
                  ID
                </button>
              )}
              {/* <button
                onClick={() => {
                  changeLanguage("id");
                }}
                className={`cs_btn cs_style_1  cs_btn_white `}
              >
                ID
              </button>
              <button
                onClick={() => {
                  changeLanguage("en");
                }}
                className={`cs_btn cs_style_1  ${
                  colorVariant ? "cs_btn_white" : ""
                }`}
              >
                EN
              </button> */}
              {/* <Link
                to={actionBtnUrl}
                className={`cs_btn cs_style_1  ${
                  colorVariant ? "cs_btn_white" : ""
                }`}
              >
                {actionBtnText}
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
