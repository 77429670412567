import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Pages/Home";
import AboutPage from "./components/Pages/Temp/AboutPage";
import PortfolioPage from "./components/Pages/PortfolioPage";
import { PortfolioDetailsPage } from "./components/Portfolio/PortfolioDetailsPage";
import ContactPage from "./components/Pages/ContactPage";
import ErrorPage from "./components/Pages/ErrorPage";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <Routes>
      <Route path="/" element={<Layout darkMode />}>
        <Route index element={<Home />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="portfolio" element={<PortfolioPage />} />
        <Route path="portfolio/:slug" element={<PortfolioDetailsPage />} />
        <Route path="contact" element={<ContactPage />} />
      </Route>
      {/* <Route path="/light/" element={<Layout />}>
        <Route path="about" element={<AboutPage />} />
        <Route path="portfolio" element={<PortfolioPage />} />
        <Route path="portfolio/:slug" element={<PortfolioDetailsPage />} />
        <Route path="contact" element={<ContactPage />} />
      </Route> */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
