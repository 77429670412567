import React from "react";

const ContentSiadum = () => {
  return (
    <div>
      <p>
        Siadum was built to address issues such as the extensive scope of its
        tasks, covering 12 Community Health Centers throughout Bangka Regency, 3
        Regional General Hospitals, and 2 other Implementation Unit, resulting
        in Duplicate SPPDs within one day, Lack of alignment between Assignment
        Letters and Leave/Permission Missing SPJ data. Many errors/revisions in
        administrative document creation. Slow mobility process. Inaccurate data
        in summary data compared to real data.
      </p>
      <h6>Objectives :</h6>
      <ul>
        <li>Optimizing operational efficiency.</li>
        <li>Reducing errors.</li>
        <li>Improving Accuracy and Time Savings.</li>
        <li>Supporting other Sustainable Activities</li>
      </ul>
      <h6>Innovations / Added Value :</h6>
      <ul>
        <li>Automation of various administrative processes.</li>
        <li>High Data Accuracy.</li>
        <li>Digitally documented.</li>
        <li>Continuous development.</li>
        <li>Accessible via Computer or Smartphone browser.</li>
        <li>
          Submission and Approval processes can be done anytime and anywhere.
        </li>
        <li>Assignment Letters and Leaves can be easily monitored.</li>
      </ul>
      <h6>Impact :</h6>
      <ul>
        <li>
          Well-documented supervision, and quick and precise actions taken
        </li>
        <li>
          Data Accuracy: 100% no duplicate SPPDs and conflicts between
          Assignment Letters and Leave / Permission Letters
        </li>
        <li>Time, paper, ink savings</li>
      </ul>
      <h6>Traffic Statistics for January 2023 - August 2023 Period</h6>
      <ul>
        <li>Total visits reached 9,817 visits</li>
        <li>
          Total Pages loaded are 579,671 or an average of 59 pages per visitor
        </li>
        <li>
          Total Clicks/Hits are 803,295 clicks or an average of 81 clicks per
          visitor
        </li>
      </ul>
      <div className="row">
        <div className="col-lg-6">
          <h6>Main Features : </h6>
          <ul>
            <li>Submission of Assignment Letters</li>
            <li>Submission of Travel Assignment Letters</li>
            <li>Overtime Submission</li>
            <li>Leave and Permission Submission</li>
            <li>Reporting for each SPT / SPPD</li>
          </ul>
        </div>
        <div className="col-lg-6">
          <h6>Additional Features : </h6>
          <ul>
            <li>Live Chat</li>
            <li>Activity Monitoring</li>
            <li>
              Community Health Center Website News Monitoring with Rest API
            </li>
            <li>
              Community Satisfaction Index Monitoring (e-Survey) with Rest API
            </li>
            <li>Monitoring Reports based on Issued Assignment Letters</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContentSiadum;
