import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import Portfolio from "../Portfolio";
import CtaStyle2 from "../Cta/CtaStyle2";
import { pageTitle } from "../../helpers/PageTitle";
import { useTranslation } from "react-i18next";

export default function PortfolioPage() {
  const { t } = useTranslation("global");
  pageTitle(t("section.portfolio.title"));
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title={t("section.portfolio.sec_subtitle")}
        subTitle={t("section.portfolio.sec_title")}
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <Portfolio />
        <div className="cs_height_75 cs_height_lg_40" />
        <CtaStyle2
          title={t("section.portfolio.buttom_text")}
          btnText={t("section.portfolio.button_contac")}
          btnUrl="/contact"
        />
        <div className="cs_height_150 cs_height_lg_80" />
      </div>
    </>
  );
}
