import { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import "./wsp-gallery.css";

const WSPGallery = ({ galleryImages, baseUrl }) => {
  console.log(baseUrl + galleryImages);
  const [slideNumber, setSlideNumber] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = (index) => {
    setSlideNumber(index);
    setIsDesktop(window.innerWidth > 768); // Periksa kembali lebar layar saat membuka modal
    if (isDesktop) setOpenModal(true);
    // setOpenModal(true);
  };

  // Close Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Previous Image
  const prevSlide = () => {
    slideNumber === 0
      ? setSlideNumber(galleryImages.length - 1)
      : setSlideNumber(slideNumber - 1);
  };

  // Next Image
  const nextSlide = () => {
    slideNumber + 1 === galleryImages.length
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Atur batasan lebar layar untuk desktop sesuai kebutuhan Anda
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Panggil sekali saat komponen dimuat untuk menginisialisasi nilai

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {openModal && (
        <div className="sliderWrap" onClick={handleCloseModal}>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose"
            size="2x"
            onClick={handleCloseModal}
          />
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            className="btnPrev"
            size="3x"
            onClick={(e) => {
              prevSlide();
              e.stopPropagation();
            }}
          />
          <FontAwesomeIcon
            icon={faCircleChevronRight}
            className="btnNext"
            size="3x"
            onClick={(e) => {
              nextSlide();
              e.stopPropagation();
            }}
          />
          <div
            className="fullScreenImage"
            onClick={(e) => {
              nextSlide();

              e.stopPropagation();
            }}
          >
            <img src={baseUrl + galleryImages[slideNumber]} alt="" />
          </div>
        </div>
      )}
      {galleryImages &&
        galleryImages.map((slide, index) => {
          return (
            <div
              className="single mb-2"
              key={index}
              onClick={() => handleOpenModal(index)}
            >
              <img src={baseUrl + slide} alt="" />
            </div>
          );
        })}
      {/* </div> */}
    </div>
  );
};

export default WSPGallery;
