import React from "react";

const ContentSiadum = () => {
  return (
    <div>
      <p>
        Siadum dibangun untuk mengatasi masalah-masalah yang tibul seperti,
        Kompleksitas cakupan tugasnya yang sangat luas, mencakup 12 Puskesmas di
        seluruh Kabupaten Bangka, 3 RSUD, dan 2 UPT lainnya, sehingga
        menyebabkan terjadinya Duplikasi SPPD dalam waktu satu hari, Tidak
        adanya penyelarasan antara Surat Tugas dengan Cuti / Izin Data-data SPJ
        yang hilang. Banyak kesalahan/revisi saat pembuatan dokumen
        administrasi. Prosess mobilitasisasi yang lambat. Tidak akuratnya data
        pada data rekap dengan data real.
      </p>
      <h6>Tujuan :</h6>
      <ul>
        <li>Mengoptimalkan efisiensi operasional.</li>
        <li>Mengurangi kesalahan.</li>
        <li>Meningkatkan Akurasi dan Penghematan Waktu.</li>
        <li>Mendukung Aktivitas Lainnya yang Berkesinambungan</li>
      </ul>
      <h6>Kebaruan / Nilai Tambah :</h6>
      <ul>
        <li>Mengotomatisasi berbagai proses administrasi.</li>
        <li>Akurasi Data yang Tinggi.</li>
        <li>Terdokumentasi secara digital.</li>
        <li>Pengembangan berkelanjutan.</li>
        <li>Dapat diaksess melalui browser Komputer maupun Smartphone.</li>
        <li>
          Prosess Pengajuan dan Approval dapat dilakukan kapan saja dan dimana
          saja.
        </li>
        <li>Surat Perintah Tugas dan Cuti dapat dimonitoring lebih mudah.</li>
      </ul>
      <h6>Dampak : </h6>
      <ul>
        <li>
          Pengawasan terdokumentasi dengan baik, dan pengambilan langkah cepat
          dan tepat
        </li>
        <li>
          Akurasi Data : 100% tidak adanya duplikasi SPPD dan bentrokan antara
          Surat Tugas dan Surat Izin / Cuti
        </li>
        <li>Pengehematan disisi waktu, kertas, tinta</li>
      </ul>
      <h6>Statistik Trafik Periode Januari 2023 - Agustus 2023</h6>
      <ul>
        <li>Jumlah kunjungan mencapai 9.817 kunjungan</li>
        <li>
          Jumlah Halaman/Pages yang diload yaitu 579.671 atau rata-rata setiap
          pengunjung memuat 59 halaman
        </li>
        <li>
          Jumlah Klik/Hits yaitu 803.295 klik atau rata-rata setiap pengunjung
          mengklik sebanyak 81 klik
        </li>
      </ul>
      <div className="row">
        <div className="col-lg-6">
          <h6>Fitur Utama : </h6>
          <ul>
            <li>Pengajuan Surat Perintah Tugas</li>
            <li>Pengajuan Surat Perintah Perjalanan Dinas</li>
            <li>Pengajuan Lembur</li>
            <li>Pengajuan Izin dan Cuti</li>
            <li>Laporan Setiap SPT / SPPD</li>
          </ul>
        </div>
        <div className="col-lg-6">
          <h6>Fitur Tambahan : </h6>
          <ul>
            <li>Live Chat</li>
            <li>Monitoring Kegiatan</li>
            <li>Monitoring Berita Website Puskesmas dengan Rest API</li>
            <li>
              Monitoring Indeks Kepuasan Masyarakat (e-Survey) dengan Rest API
            </li>
            <li>Monitoring Laporan berdasarkan Surat Tugas yang diterbitkan</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ContentSiadum;
