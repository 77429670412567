// import React from "react";
import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { Icon } from "@iconify/react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { data_porto } from "./data_porto";
import ContentSiadumId from "./content/content_id";
import ContentSiadumEn from "./content/content_en";
import WSPGallery from "../WSPGallery";
export function PortfolioDetailsPage() {
  const { slug } = useParams();
  const { t, i18n } = useTranslation("porto");
  const data = data_porto[slug];
  var lang = i18n.language;

  //
  const keys = Object.keys(data_porto);
  const currentIndex = keys.indexOf(slug);
  const prevIndex = keys[currentIndex > 0 ? currentIndex - 1 : keys.length - 1];

  // Mendapatkan data untuk indeks selanjutnya
  const nextIndex = keys[currentIndex < keys.length - 1 ? currentIndex + 1 : 0];

  console.log("nextIndex : " + nextIndex);
  console.log("portoPrev : " + prevIndex);
  //
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title={t("datas." + slug + ".title")}
        subTitle={t("page.detail")}
        tags={data.tags}
      />

      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src={`/images/portopolio/${data.cover}`} alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <ul className="cs_portfolio_details_info cs_mp0">
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">
                  {t("page.client")}:
                </h3>
                <p className="mb-0">{t("datas." + slug + ".client")} </p>
              </li>
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">Link:</h3>
                <a className="mb-0" href={data.link}>
                  {data.link_label ? data.link_label : data.link}
                </a>
              </li>
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">
                  {t("page.location")}:
                </h3>
                <p className="mb-0">{t("datas." + slug + ".location")}</p>
              </li>
              {t("datas." + slug + ".budget") !== "-" ? (
                <li>
                  <h3 className="cs_fs_21 mb-0 cs_semibold">
                    {t("page.budget")} :
                  </h3>
                  <p className="mb-0">{t("datas." + slug + ".budget")}</p>
                </li>
              ) : (
                ""
              )}
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">
                  {t("page.times")}:
                </h3>
                <p className="mb-0">{t("datas." + slug + ".times")}</p>
              </li>
            </ul>
            <div className="cs_portfolio_details_right">
              <h2>{t("page.title")}</h2>
              {slug === "siadum-dinkes" &&
                (lang === "id" ? <ContentSiadumId /> : <ContentSiadumEn />)}
              <div
                dangerouslySetInnerHTML={{
                  __html: t("datas." + slug + ".content"),
                }}
              />
              <br></br>
              <h5>{t("page.tools")} : </h5>
              <ul>
                {data.tools.map((tool, index) => (
                  <li key={index}>{tool}</li>
                ))}
              </ul>
            </div>
          </div>
          <Spacing lg="115" md="60" />
          <div className="cs_portfolio_details_gallery">
            {(data.gdrive_vid ? data.gdrive_vid : []).map((vid, index) => (
              <div
                key={index}
                className="embed-responsive embed-responsive-16by9"
              >
                <iframe
                  title="Video"
                  src={vid}
                  className="embed-responsive-item"
                  allow="autoplay"
                ></iframe>
              </div>
            ))}
          </div>
          <Spacing lg="115" md="60" />
          <h4>Galeri Project</h4>
          <div className="cs_portfolio_details_gallery">
            <WSPGallery
              galleryImages={data.images}
              baseUrl={"/images/portopolio/"}
            />
          </div>
        </div>
        <Spacing lg="90" md="60" />
        <div className="cs_page_navigation cs_center">
          <div>
            <Link
              to={"/portfolio/" + prevIndex}
              className="cs_text_btn cs_type1"
            >
              <Icon icon="cil:arrow-left" className="cs_fs_21" />
              <span>Prev Project</span>
            </Link>
          </div>
          <div>
            <Link to={"/portfolio/" + nextIndex} className="cs_text_btn">
              <span>Next Project</span>
              <Icon icon="cil:arrow-right" className="cs_fs_21" />
            </Link>
          </div>
        </div>
      </div>
      <Spacing lg="150" md="80" />
    </>
  );
}
