import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css";
import "slick-carousel/slick/slick.css";
import "./sass/index.scss";
import global_en from "./lang/en/global.json";
import global_id from "./lang/id/global.json";

import porto_en from "./lang/en/data_porto.json";
import porto_id from "./lang/id/data_porto.json";
import i18next from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    debug: true,
    lng: "en",
    fallbackLng: "en",
    resources: {
      en: {
        global: global_en,
        porto: porto_en,
      },
      id: {
        global: global_id,
        porto: porto_id,
      },
    },
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <Suspense fallback="loading"> */}
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </I18nextProvider>
    {/* </Suspense> */}
  </React.StrictMode>
);
