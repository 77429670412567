import React from "react";
import Masonry from "react-masonry-css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { data_porto } from "./data_porto";

var portfolioData = [
  "psc-119-app",
  "psc-119-bangka",
  "kpb-lada-babel",
  "fkm-unsri",
  "pt-indometal-asia",
  "siadum-dinkes",
  "mpp-bangka",
];
export default function Portfolio({ compact_data }) {
  const breakpoints = {
    default: 2,
    991: 1,
  };
  // const { compact_data } = useParams();
  const useData = compact_data ? compact_data : portfolioData;
  const { t } = useTranslation("porto");
  const data = data_porto;
  return (
    <>
      <Masonry
        breakpointCols={breakpoints}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {useData.map((item, index) => (
          // {console.log(index)}
          <div key={index} className="masonry-item">
            <div className="cs_portfolio cs_style_1">
              <Link
                to={"/portfolio/" + item}
                className="cs_portfolio_thumb cs_radius_15"
              >
                <img
                  src={"/images/portopolio/" + data[item]["cover"]}
                  alt="Portfolio"
                />
              </Link>
              <div className="cs_portfolio_info">
                <h2 className="cs_portfolio_title cs_fs_38">
                  <Link to={"/portfolio/" + item}>
                    {t("datas." + item + ".title")}
                  </Link>
                </h2>
                {data[item].tags && (
                  <p className="cs-post_meta cs-ternary_color">
                    Tags :
                    <span className="cs-posted_by">
                      {(data[item].tags ? data[item].tags : []).map(
                        (tag, index) => (
                          <Link to={"/tag/" + tag}>
                            <span key={index}> #{tag} </span>
                          </Link>
                        )
                      )}
                    </span>
                  </p>
                )}
                <Link to={"/portfolio/" + item} className="cs_portfolio_btn">
                  {t("page.see_project")}
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Masonry>
    </>
  );
}
