export const data_porto = {
  "kpb-lada-babel": {
    cover: "kpb-cover-mini.png",
    link: "https://kpbladababel.co.id/",
    link_label: "kpbladababel.co.id",
    images: ["kpb-full.png", "kpb-1.jpg", "kpb-2.png"],
    tags: ["web aplication", "codeigniter", "rest full api"],
    tools: [
      "Codeigniter",
      "Javascript",
      "Jquery",
      "Ajax",
      "REST Full API",
      "HTML 5",
      "Css",
      "MYSQL",
    ],
  },
  "fkm-unsri": {
    cover: "fkm-cover.png",
    link: "http://www.fkm.unsri.ac.id/",
    link_label: "fkm.unsri.ac.id",
    images: ["fkm-2.png", "fkm-1.png", "fkm-3.png"],
    tools: [
      "Codeigniter",
      "Javascript",
      "Jquery",
      "Ajax",
      "HTML 5",
      "Css",
      "MYSQL",
    ],
  },
  "pt-indometal-asia": {
    cover: "ima-cover.png",
    link: "https://indometalasia.com/",
    link_label: "indometalasia.com",
    images: [
      "ima-full.png",
      "ima-sim-1.png",
      "ima-sim-2.png",
      "ima-sim-3.png",
      "ima-sim-4.png",
      "ima-sim-5.png",
    ],
    tools: [
      "Codeigniter",
      "Javascript",
      "Jquery",
      "Ajax",
      "HTML 5",
      "Css",
      "MYSQL",
    ],
  },
  "siadum-dinkes": {
    cover: "siadum-cover.png",
    link: "https://dinkes.bangka.go.id/",
    link_label: "dinkes.bangka.go.id",
    images: ["siadum-cover.png"],
    gdrive_vid: [
      "https://drive.google.com/file/d/1onlgW6-i9S3XBMG25kuQ-GYcBd6P7S63/preview",
    ],
    tools: [
      "Codeigniter",
      "Javascript",
      "Jquery",
      "Ajax",
      "REST FULL API",
      "HTML 5",
      "Css",
      "MYSQL",
    ],
    tags: ["web aplication", "codeigniter", "rest full api"],
  },
  "psc-119-app": {
    cover: "psc-app/cover.png",
    link: "https://psc.bangka.go.id/",
    link_label: "psc.bangka.go.id",
    images: [
      "psc-app/cover.png",
      "psc-app/1.png",
      "psc-app/2.png",
      "psc-app/3.png",
      "psc-app/4.png",
      "psc-app/5.png",
    ],
    gdrive_vid: [
      "https://drive.google.com/file/d/1ntzhZRkqND-1p7brnZzhN1w6YXQ-xDLN/preview",
    ],
    tools: [
      "Flutter",
      "Laravel",
      "Javascript",
      "Jquery",
      "Ajax",
      "REST FULL API",
      "HTML 5",
      "Css",
      "MYSQL",
    ],
    tags: [
      "web aplication",
      "mobile aplication",
      "flutter",
      "laravel",
      "rest full api",
    ],
  },
  "psc-119-bangka": {
    cover: "psc/cover.png",
    link: "https://psc.bangka.go.id/",
    link_label: "psc.bangka.go.id",
    images: [
      "psc/cover.png",
      //   "ima-sim-1.png",
      //   "ima-sim-2.png",
      //   "ima-sim-3.png",
      //   "ima-sim-4.png",
      //   "ima-sim-5.png",
    ],
    tools: ["Laravel", "Javascript", "REST FULL API", "React JS", "MYSQL"],
    tags: [
      "web aplication",
      "mobile aplication",
      "web content",
      "laravel",
      "rest full api",
      "react",
      "flutter",
    ],
    // tag: ["Laravel", "Javascript", "REST FULL API", "React JS", "MYSQL"],
  },
  "mpp-bangka": {
    cover: "mpp/cover.png",
    link: "https://ugikdev.site/project/mpp/",
    link_label: "project/mpp",
    images: [
      "mpp/cover.png",
      "mpp/1.png",
      "mpp/2.png",
      "mpp/3.png",
      "mpp/4.png",
      "mpp/5.png",
      "mpp/6.png",
      "mpp/7.png",
      // "mpp/8.png",
      //   "ima-sim-1.png",
      //   "ima-sim-2.png",
      //   "ima-sim-3.png",
      //   "ima-sim-4.png",
      //   "ima-sim-5.png",
    ],
    tools: ["Laravel", "Javascript", "REST FULL API", "MYSQL"],
    tags: ["web aplication", "mobile aplication", "web content", "laravel"],
    // tag: ["Laravel", "Javascript", "REST FULL API", "React JS", "MYSQL"],
  },
  "secret-project": {
    cover: "logo.png",
    link: "#",
    link_label: "-",
    images: [
      "logo.png",
      //   "ima-sim-1.png",
      //   "ima-sim-2.png",
      //   "ima-sim-3.png",
      //   "ima-sim-4.png",
      //   "ima-sim-5.png",
    ],
    tools: ["Laravel", "Codeigniter", "Javascript", "REST FULL API", "MYSQL"],
    tags: [
      "web aplication",
      "mobile aplication",
      "web content",
      "laravel",
      "rest full api",
      "react",
      "flutter",
    ],
    // tag: ["Laravel", "Javascript", "REST FULL API", "React JS", "MYSQL"],
  },
};
