import React from "react";
import Spacing from "../../Spacing";
// import IconBoxStyle6 from "../../IconBox/IconBoxStyle6";
import IconBoxStyle6 from "../../IconBox/IconBoxStyle4";
import SectionHeadingStyle5 from "../../SectionHeading/SectionHeadingStyle5";
import { useTranslation } from "react-i18next";

export default function HowWork({ darkMode }) {
  const { t } = useTranslation("global");

  const icons = [
    "calendar.svg",
    "search.svg",
    "design.svg",
    "code.svg",
    "check.svg",
    "gear.svg",
    "tools.svg",
    "chart-line.svg",
  ];
  return (
    <>
      <Spacing lg="75" md="60" />

      <section className="cs_primary_bg">
        <Spacing lg="140" md="70" />
        <div className="container">
          <SectionHeadingStyle5 title={t("section.howwork.title")} />
          <Spacing lg="85" md="45" />
          {/* <div className="cs_working_process_wrap cs_center col-lg-12"> */}
          {/* <div className="cs_working_process col-lg-12"> */}
          <div
            className="row g-3"
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {icons.map((icon, index) => {
              return (
                <div
                  key={index}
                  className="col-lg-6"
                  style={{ display: "flex" }}
                >
                  <IconBoxStyle6
                    bgSrc="/images/others/process_1.png"
                    iconSrc={"/images/icons/" + icon}
                    title={t("section.howwork." + (index + 1) + ".title")}
                    subTitle={t("section.howwork." + (index + 1) + ".subtitle")}
                    btnUrl={false}
                  />
                </div>
              );
            })}
          </div>
          {/* <div className="cs_working_process_col">
                <IconBoxStyle6
                  href="/images/others/process_1.png"
                  iconSrc="/images/icons/search.svg"
                  title={t("section.howwork.3.title")}
                  subTitle={t("section.howwork.1.subtitle")}
                />
              </div> */}
          {/* <div className="cs_working_process_col">
                <IconBoxStyle6
                  bgSrc="/images/others/process_1.png"
                  iconSrc="/images/icons/idea.svg"
                  title={t("section.howwork.3.title")}
                  subTitle={t("section.howwork.2.subtitle")}
                />
              </div>
              <div className="cs_working_process_col ">
                <IconBoxStyle6
                  bgSrc="/images/others/process_3.png"
                  iconSrc="/images/icons/gear.svg"
                  title={t("section.howwork.3.title")}
                  subTitle={t("section.howwork.3.subtitle")}
                />
              </div> */}
        </div>
        {/* </div> */}
        {/* </div> */}
        <Spacing lg="150" md="80" />
      </section>
      {/* <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="Meet our experts team behind <br />the ugik agency"
            subTitle="Our Team"
          />
          <Spacing lg="85" md="45" />
        </div>
        <TeamSlider data={teamData} />
      </section>
      <Spacing lg="135" md="70" />
      <Marquee text="We Create Design - Build App - Website - Branding - SEO" />
      <Spacing lg="84" md="50" />
      <div className="container">
        <Brands data={darkMode ? brandListDark : brandList} />
      </div> */}
    </>
  );
}
