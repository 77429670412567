import React from "react";
import Spacing from "../../Spacing";
import ProgressBar from "../../ProgressBar";

const hardSkill = [
  {
    title: "Back End",
    percentage: "90",
  },
  {
    title: "Front End",
    percentage: "80",
  },
  {
    title: "Database Problem Solve",
    percentage: "100",
  },
  {
    title: "Relational Database",
    percentage: "100",
  },
  {
    title: "REST API",
    percentage: "90",
  },
  {
    title: "Team Work",
    percentage: "90",
  },
  {
    title: "Computer Troubleshot",
    percentage: "90",
  },
  {
    title: "Network Troubleshot",
    percentage: "90",
  },
];

const freameworkSkill = [
  {
    title: "Laravel",
    percentage: "85",
  },
  {
    title: "Codeigniter",
    percentage: "95",
  },
  {
    title: "Flutter",
    percentage: "75",
  },
  {
    title: "React",
    percentage: "60",
  },
];
const languangeSkill = [
  {
    title: "HTML",
    percentage: "90",
  },
  {
    title: "Jquery",
    percentage: "90",
  },
  {
    title: "PHP",
    percentage: "95",
  },
  {
    title: "Javascript",
    percentage: "95",
  },
];

export default function Skills({ darkMode }) {
  // pageTitle("Skills");
  return (
    <>
      <div className={`cs_about cs_style_3`}>
        <div className="container">
          <div className="items-center cs_gap_y_40 pt-5">
            <h2 className="cs_fs_50 mb-0 pt-2">Skills</h2>
          </div>

          <div className="row align-items-center cs_gap_y_40">
            <div className="col-lg-5">
              <div className="cs_section_heading cs_style_1">
                <svg
                  width={84}
                  height={77}
                  viewBox="0 0 84 77"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="0.5">
                    <path
                      d="M18.6368 15.0076C22.4932 17.1059 25.7717 20.1708 29.4921 22.4974C33.1398 24.7754 36.5397 27.5246 39.8473 30.2349C46.5548 35.7187 52.6795 42.1594 56.8128 49.8046C57.3616 50.8198 56.0211 51.9223 55.1857 51.0578C49.2796 44.9037 43.2132 38.9147 37.0448 33.0375C34.0432 30.1685 30.9994 27.3368 27.9136 24.5423C24.6497 21.594 20.905 19.1994 17.6848 16.2413C17.0145 15.6196 17.8742 14.5899 18.6368 15.0076Z"
                      fill="#4F4747"
                    />
                    <path
                      d="M1.09208 43.7783C5.48233 43.7557 9.8326 44.8586 14.2139 45.1016C18.5079 45.3372 22.8122 46.105 27.0169 46.8833C35.5377 48.4507 44.0096 51.1374 51.3182 55.84C52.2887 56.4644 51.6464 58.0769 50.4975 57.7226C42.3554 55.1815 34.1525 52.8624 25.9141 50.6903C21.9009 49.6254 17.8687 48.6134 13.8176 47.6544C9.53638 46.6465 5.10105 46.3557 0.853385 45.3183C-0.0336496 45.097 0.222624 43.7803 1.09208 43.7783Z"
                      fill="#4F4747"
                    />
                    <path
                      d="M51.175 0.725762C53.2885 4.57405 54.3937 8.92393 56.2694 12.891C58.1101 16.7778 59.4879 20.9274 60.809 24.9946C63.4947 33.2318 65.1734 41.9599 64.5255 50.6268C64.4394 51.7776 62.7158 51.982 62.4793 50.8032C60.83 42.4344 58.9566 34.1181 56.937 25.8407C55.9591 21.8052 54.9257 17.7783 53.8368 13.76C52.681 9.51616 50.8215 5.47878 49.7077 1.25032C49.4791 0.365115 50.7587 -0.0375394 51.175 0.725762Z"
                      fill="#4F4747"
                    />
                  </g>
                </svg>
                {hardSkill.map((item, index) => (
                  <ProgressBar
                    key={index}
                    title={item.title}
                    percentage={item.percentage}
                  />
                ))}
                <Spacing lg="30" md="30" />
              </div>
            </div>
            <div className="col-lg-5 offset-lg-2">
              <div className="cs_section_heading cs_style_1">
                <h5>Programming Language</h5>
                {languangeSkill.map((item, index) => (
                  <ProgressBar
                    key={index}
                    title={item.title}
                    percentage={item.percentage}
                  />
                ))}
                <Spacing lg="40" md="40" />
                <h5>Freamwork</h5>
                {freameworkSkill.map((item, index) => (
                  <ProgressBar
                    key={index}
                    title={item.title}
                    percentage={item.percentage}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spacing lg="125" md="70" />
    </>
  );
}
